import React from 'react';
import {
  DidomiButton,
  DidomiIcon,
  DidomiModal,
  DidomiModalActions,
  DidomiModalContent,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';

type TextAddedSuccessModalProps = {
  isOpen: boolean;
  onCloseClicked: () => void;
};

export const TextAddedSuccessModal = ({ isOpen, onCloseClicked }: TextAddedSuccessModalProps) => (
  <DidomiModal isOpen={isOpen} variant="slim">
    <DidomiIcon className="mb-xs text-primary-blue-6" name="pmp-success" />
    <DidomiModalHeader modalSubTitle="Well done!" modalDescription="Your text has been submitted" />

    <DidomiModalContent>
      <p className="text-primary-blue-6">
        We will study your text to check its conformity. Once approved, your text will be available
        for you to use in your notices.
      </p>
    </DidomiModalContent>

    <DidomiModalActions>
      <DidomiButton onClick={onCloseClicked}>I got it, thanks</DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
