import React from 'react';
import { DidomiIcon, DidomiInstructionStep } from '@didomi/ui-atoms-react';

export const HowToInstruction = () => {
  return (
    <section>
      <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mb-s">
        How to get your own text approved?
      </h2>

      <ol className="grid lg:grid-cols-2 gap-s">
        <DidomiInstructionStep step={1} variant="info" title-text="Send us your purposed text">
          <>
            <p className="text-body-small text-primary-blue-6 mb-2">
              Please submit your texts to{' '}
              <a href="mailto:support@didomi.io" className="text-primary-blue-4">
                support@didomi.io
              </a>{' '}
              or to your Customer Success Manager indicating your account name and both the name of
              the text and its content.
            </p>

            <a
              href="https://support.didomi.io/add-your-own-standard-texts-in-the-didomi-console"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-button-small text-primary-blue-6 font-semibold"
            >
              Learn more
              <DidomiIcon name="export" />
            </a>
          </>
        </DidomiInstructionStep>

        <DidomiInstructionStep step={2} variant="info" title-text="Use it in your notices">
          <p className="text-body-small text-primary-blue-6">
            Once the Didomi team receives your email, it will be reviewed, and when validated you
            will be able to select it in your notices instead of Didomi&rsquo;s standard text.
          </p>
        </DidomiInstructionStep>
      </ol>
    </section>
  );
};
