import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  DidomiModal,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiSelectOptions,
  DidomiSelectOption,
  DidomiSelectField,
  DidomiTextInputField,
  DidomiCheckboxField,
  DidomiHintbox,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  NewStandardText,
  NewStandardTextContent,
  StandardTextPlatforms,
  TextLanguageData,
} from '@types';
import { PLATFORM_LIST_OPTIONS } from '@utils';
import { StandardTextLanguageCard } from './StandardTextLanguageCard';
import { StandardTextLanguageForm } from './StandardTextLanguageForm';

const INITIAL_FORM_STATE = {
  name: '',
  includes_purposes: true,
  platforms: [],
  default: false,
};

const StandardTextSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
  includes_purposes: Yup.boolean(),
  platforms: Yup.array().of(Yup.string()).min(1, 'Required'),
  default: Yup.boolean(),
});

type AddStandardTextModalProps = {
  isOpen: boolean;
  error?: string;
  defaultPlatforms: Set<StandardTextPlatforms>;
  onCancel?: () => void;
  onSave?: (standardTextData: {
    standardText: NewStandardText;
    standardTextContent: NewStandardTextContent;
  }) => void;
};

export const AddStandardTextModal = ({
  isOpen,
  error,
  defaultPlatforms,
  onCancel,
  onSave,
}: AddStandardTextModalProps) => {
  const [textLanguages, setTextLanguages] = useState<TextLanguageData[]>([]);
  const [activeLanguageForm, setActiveLanguageForm] = useState<'new' | string | null>(null);
  const formikRef = useRef(null);

  const selectedLanguages = useMemo(() => textLanguages.map(l => l.language), [textLanguages]);

  useEffect(() => {
    if (!isOpen) {
      formikRef.current?.resetForm();
    }

    setActiveLanguageForm('new');
    setTextLanguages([]);
  }, [isOpen]);

  const checkIfPlatformAlreadyDefault = (p: StandardTextPlatforms) => defaultPlatforms.has(p);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const handleAddLanguage = (languageData: TextLanguageData) => {
    setTextLanguages([...textLanguages, languageData]);
    setActiveLanguageForm(null);
  };

  const handleDeleteLanguage = (languageToDelete: string) => {
    const filteredLanguages = textLanguages.filter(l => l.language !== languageToDelete);
    setTextLanguages(filteredLanguages);
  };

  const handleUpdateLanguage = (languageData: TextLanguageData, originalLanguage: string) => {
    const updatedLanguages = textLanguages.map(l =>
      l.language === originalLanguage ? languageData : l,
    );
    setTextLanguages(updatedLanguages);
    setActiveLanguageForm(null);
  };

  const handleSave = (values: typeof INITIAL_FORM_STATE) => {
    const standardText = {
      name: values.name,
      default: values.default,
      platforms: values.platforms as StandardTextPlatforms[],
    };

    const content = textLanguages.reduce(
      (acc, langData) => ({ ...acc, [langData.language]: langData.content }),
      {},
    );
    const standardTextContent = {
      content,
      includes_purposes: values.includes_purposes,
      status: 'submitted' as const,
    };

    onSave({ standardText, standardTextContent });
  };

  return (
    <DidomiModal isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <div className={tx('text-body-extra-small text-primary-blue-6')}>Standard text</div>
      <div
        className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-s')}
        data-testid="add-text-modal-title"
      >
        Create a new text
      </div>

      <div className={tx('text-primary-blue-6 mb-s text-body-small leading-[20px]')}>
        You can add text macros for dynamic values.{' '}
        <a
          className={tx('underline')}
          href="https://support.didomi.io/how-to-use-number-of-vendors-macros"
          target="_blanc"
          rel="noopener noreferrer"
        >
          How to use text macros
        </a>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={INITIAL_FORM_STATE}
        validationSchema={StandardTextSchema}
        onSubmit={handleSave}
      >
        {({ values, errors, touched }) => (
          <Form className={tx('w-full')} noValidate>
            <DidomiModalContent className={tx('text-left')}>
              <div>
                {error && (
                  <DidomiHintbox
                    className={tx('mb-s')}
                    titleText="API Error"
                    variant="failure"
                    iconName="failure"
                  >
                    {error}
                  </DidomiHintbox>
                )}

                <DidomiTextInputField
                  label="Text name"
                  name="name"
                  placeholder="Type your standard text name..."
                  required
                />

                <DidomiSelectField
                  className={tx('mb-2')}
                  label="Platforms"
                  name="platforms"
                  placeholder="Select platforms"
                  multi={true}
                  required
                  hideErrorMessage={!(errors.platforms && touched.platforms)}
                >
                  <DidomiSelectOptions>
                    {PLATFORM_LIST_OPTIONS.map(platform => (
                      <DidomiSelectOption
                        key={platform.label}
                        value={platform.value}
                        label={platform.label}
                        leftIcon={platform.name}
                      ></DidomiSelectOption>
                    ))}
                  </DidomiSelectOptions>
                </DidomiSelectField>
                <DidomiCheckboxField
                  checkboxId="default"
                  name="default"
                  label="Default text for selected platforms"
                  disabled={
                    values.platforms.length === 0 ||
                    values.platforms.some(checkIfPlatformAlreadyDefault)
                  }
                />

                <h3 className={tx('font-semibold text-secondary-cobalt-blue-4 mb-2')}>
                  Text languages
                </h3>
                {(textLanguages.length !== 0 || activeLanguageForm === 'new') && (
                  <ul
                    className={tx('flex flex-col gap-xxs mb-s')}
                    data-testid="added-text-languages"
                  >
                    {textLanguages.map(langData => (
                      <li key={langData.language}>
                        {activeLanguageForm === langData.language ? (
                          <StandardTextLanguageForm
                            languageData={langData}
                            disabledLanguages={selectedLanguages.filter(
                              l => l !== langData.language,
                            )}
                            onSave={updates => handleUpdateLanguage(updates, langData.language)}
                            onCancel={() => setActiveLanguageForm(null)}
                          />
                        ) : (
                          <StandardTextLanguageCard
                            languageData={langData}
                            onEdit={() => setActiveLanguageForm(langData.language)}
                            onDelete={() => handleDeleteLanguage(langData.language)}
                          />
                        )}
                      </li>
                    ))}

                    {activeLanguageForm === 'new' && (
                      <li>
                        <StandardTextLanguageForm
                          disabledLanguages={selectedLanguages}
                          onSave={handleAddLanguage}
                          onCancel={() => setActiveLanguageForm(null)}
                        />
                      </li>
                    )}
                  </ul>
                )}

                <DidomiButton
                  variant="secondary"
                  iconRight="new-create"
                  className={tx('w-full mb-s')}
                  disabled={activeLanguageForm === 'new'}
                  onClick={() => setActiveLanguageForm('new')}
                >
                  Add a language
                </DidomiButton>

                <div className={tx('mb-s')}>
                  <DidomiCheckboxField
                    checkboxId="includes_purposes"
                    label="The text includes mentions of purposes"
                    name="includes_purposes"
                    hideErrorMessage
                  />

                  {!values.includes_purposes && (
                    <DidomiHintbox
                      data-testid="legitimate_interest_warning"
                      variant="warning"
                      iconName="warning"
                    >
                      Not mentioning purposes is acceptable under the TCF if the list of Data
                      processing is displayed in the first layer of the notice.
                    </DidomiHintbox>
                  )}
                </div>

                <div className={tx('text-left text-primary-blue-6')} style={{ fontSize: '12px' }}>
                  * <span className={tx('italic')}>Fields are required</span>
                </div>
              </div>
            </DidomiModalContent>

            <DidomiModalActions>
              <DidomiButton
                data-testid="cancel-text-modal"
                type="button"
                variant="secondary"
                onClick={onCancel}
              >
                Cancel
              </DidomiButton>
              <DidomiButton type="submit" disabled={textLanguages.length === 0}>
                Submit
              </DidomiButton>
            </DidomiModalActions>
          </Form>
        )}
      </Formik>
    </DidomiModal>
  );
};
