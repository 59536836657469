import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';

export const WhatIsStandardText = () => {
  return (
    <div className="text-primary-blue-6">
      <h2 className="h2 mb-s text-primary-blue-6">What is a Standard text?</h2>

      <p className="text-h3 font-serif mb-s">
        A standard text is a text you create by your own to add into your consent notice instead of
        the predefined IAB TCF text.
      </p>

      <p className="text-h3 font-serif mb-s">
        To be able to use the TCF integration, you must ensure that{' '}
        <span className="text-primary-blue-4 font-bold">
          your own notice texts are compliant with IAB TCF policies
        </span>
        .
      </p>

      <p className="text-body-normal mb-s">
        You can ask Didomi’s Technical Support and Customer Success teams to help you validate your
        texts. We will make those texts available in your standard texts list, ready to be used in
        your notices instead of our default text.
      </p>

      <a
        href="https://support.didomi.io/add-your-own-standard-texts-in-the-didomi-console"
        target="_blank"
        rel="noopener noreferrer"
      >
        <DidomiButton size="small" iconRight="export">
          Read the Standard text documentation
        </DidomiButton>
      </a>
    </div>
  );
};
