import React from 'react';
import { ErrorBoundary } from '@didomi/helpers-react';
import { connect } from '@didomi/ui-foundation';
import * as utility from '@didomi/utility';
import { UtilityProvider } from '@didomi/utility-react';
import { App } from '@pages';

// Setup Twind for standard-text only
connect('.standard-text-root');

const Root = () => {
  return (
    <UtilityProvider utility={utility}>
      <ErrorBoundary
        sentryConfig={{
          sentryDsn: CONFIG.sentryDsn,
          environment: CONFIG.environment,
          release: CONFIG.release,
        }}
      >
        <App />
      </ErrorBoundary>
    </UtilityProvider>
  );
};

export { Root };
