import React, { useState } from 'react';
import {
  DidomiBackButtonLink,
  DidomiDrawer,
  DidomiDrawerContent,
  DidomiSecondaryHeader,
  DidomiSmallPush,
} from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';
import { WhatIsStandardText } from '@components';

type MainLayoutProps = {
  children: React.ReactChild;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { navigateTo } = useSPARouter();

  return (
    <section className="p-l pb-20">
      <DidomiSecondaryHeader className="block mb-m" titleText="Manage Standard text">
        <div slot="back-button">
          <DidomiBackButtonLink
            text="Back to Consent notice list"
            class="cursor-pointer"
            onClick={() => navigateTo('/consent-notices')}
          ></DidomiBackButtonLink>
        </div>

        <div slot="description">Use your own notice text with the IAB TCF integration.</div>

        <div slot="actions">
          <DidomiSmallPush
            data-testid="standard-text-push-action"
            highlightedText="What is a Standard text?"
            text="Good to know!"
            smallIcon="arrow-left-sm"
            onClick={() => setIsDrawerOpen(true)}
          />
        </div>
      </DidomiSecondaryHeader>

      <hr className="h-0 mb-m border-t-1 border-neutral-gray-3" />

      <div>{children}</div>

      <DidomiDrawer
        data-testid="standard-text-drawer"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <DidomiDrawerContent>
          <WhatIsStandardText />
        </DidomiDrawerContent>
      </DidomiDrawer>
    </section>
  );
};
