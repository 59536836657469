import React, { useMemo } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import {
  DidomiControlledCollapsibleCard,
  DidomiIconButton,
  DidomiLanguageFlag,
  DidomiMenu,
  DidomiMenuItem,
} from '@didomi/ui-atoms-react';
import { TextLanguageData } from '@types';

interface StandardTextLanguageCardProps {
  languageData: TextLanguageData;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const StandardTextLanguageCard = ({
  languageData,
  onEdit,
  onDelete,
}: StandardTextLanguageCardProps) => {
  const longLanguageName = useMemo(() => {
    return supportedLanguages.find(l => l.code === languageData.language)?.longName;
  }, [languageData]);

  return (
    <DidomiControlledCollapsibleCard className="w-full">
      {/* TITLE */}
      <div slot="header-title-content" className="flex gap-xxs items-center">
        <DidomiLanguageFlag language={languageData.language} style={{ '--icon-size': '20px' }} />
        <div className="font-semibold text-body-extra-small">{longLanguageName}</div>
      </div>

      {/* EDIT/DELETE MENU */}
      <div slot="header-controls">
        <DidomiIconButton
          variant="rounded"
          icon="submenu"
          id={'menu-' + languageData.language}
          title="Language menu"
        ></DidomiIconButton>
        <DidomiMenu
          for={'menu-' + languageData.language}
          variant="main"
          placement="left-start"
          distance="xxs"
        >
          <DidomiMenuItem data-tracking="cmp-notice-remove" iconName="edit" onItemSelected={onEdit}>
            Edit
          </DidomiMenuItem>
          <DidomiMenuItem
            data-tracking="cmp-notice-remove"
            iconName="delete"
            onItemSelected={onDelete}
          >
            Delete
          </DidomiMenuItem>
        </DidomiMenu>
      </div>

      {/* BODY */}
      <div>
        <div className="font-bold text-body-extra-small">TEXT</div>
        <div className="text-body-small mb-xs">{languageData.content}</div>
      </div>
    </DidomiControlledCollapsibleCard>
  );
};
