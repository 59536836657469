import React, { useMemo, useState } from 'react';
import { useDebounce } from '@didomi/helpers-react';
import { DidomiEmptyState, DidomiLoader } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useStandardTextsState } from '@hooks';
import { AddStandardTextModal, LoadingModal, TextAddedSuccessModal } from '@modals';
import { NewStandardText, NewStandardTextContent, StatusFilterType } from '@types';
import { ACCESS_POLICIES_CONFIG, getFilteredStandardTexts } from '@utils';
import { StandardTextCard } from '../StandardTextCard/StandardTextCard';
import { StandardTextListToolbar } from '../StandardTextListToolbar/StandardTextListToolbar';

export const StandardTextList = () => {
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const [createModalData, setCreateModalData] = useState({ isOpen: false, error: null });
  const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false);
  const {
    items: standardTextItems,
    defaultPlatforms,
    addNewText,
    isLoading,
    isSaving,
  } = useStandardTextsState();
  const [statusFilter, setStatusFilter] = useState<StatusFilterType>(null);
  const [searchFilter, setSearchFilter] = useState('');
  const debounceSearchValue = useDebounce(searchFilter, 300);

  const filteredTextItems = useMemo(
    () => getFilteredStandardTexts(statusFilter, debounceSearchValue, standardTextItems),
    [statusFilter, debounceSearchValue, standardTextItems],
  );

  const isEmptyDb = standardTextItems.length === 0;
  const isEmptyFilteredResults = standardTextItems.length && !filteredTextItems.length;

  const handleAddText = async (formData: {
    standardText: NewStandardText;
    standardTextContent: NewStandardTextContent;
  }) => {
    const { error } = await addNewText(formData);

    if (error) {
      setCreateModalData({ isOpen: true, error: error });
    } else {
      setCreateModalData({ isOpen: false, error: null });
      setIsCreateSuccessModalOpen(true);
    }
  };

  return (
    <section>
      {!isEmptyDb && !isLoading && (
        <>
          <StandardTextListToolbar
            numberOfResults={filteredTextItems.length}
            isCreateVisible={isCMPEditor}
            onFilterChange={status => setStatusFilter(status)}
            onSearchChange={search => setSearchFilter(search)}
            onCreateClicked={() => setCreateModalData({ isOpen: true, error: null })}
          />

          <ul className="flex flex-col gap-6">
            {filteredTextItems.map(text => (
              <li key={text.id} data-testid="standard-text-item">
                <StandardTextCard standardText={text} searchQuery={searchFilter} />
              </li>
            ))}
          </ul>
        </>
      )}

      {/* Empty State */}
      {(isEmptyDb || isEmptyFilteredResults) && !isLoading && (
        <DidomiEmptyState
          illustration-name="traces-no-result-found"
          actionName={isCMPEditor ? 'Create a new text' : ''}
          actionIconRight={isCMPEditor ? 'new-create' : ''}
          onActionClick={() => setCreateModalData({ isOpen: true, error: null })}
        >
          <div slot="title">It&apos;s empty here!</div>
          {isEmptyDb ? 'You do not have standard text yet.' : 'No results'}
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && (
        <div className="w-full flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading Standard text</div>
        </div>
      )}

      {/* Modals */}
      <AddStandardTextModal
        isOpen={createModalData.isOpen}
        error={createModalData.error}
        defaultPlatforms={defaultPlatforms}
        onSave={handleAddText}
        onCancel={() => setCreateModalData({ isOpen: false, error: null })}
      />
      <LoadingModal
        isOpen={isSaving}
        title="Saving changes..."
        subTitle="Your text is submitted..."
      />
      <TextAddedSuccessModal
        isOpen={isCreateSuccessModalOpen}
        onCloseClicked={() => setIsCreateSuccessModalOpen(false)}
      />
    </section>
  );
};
