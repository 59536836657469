import React from 'react';
import { HowToInstruction, StandardTextList } from '@components';
import { MainLayout } from '@layouts';

export const App = () => {
  return (
    <MainLayout>
      <section className="flex flex-col gap-7">
        <HowToInstruction />

        <StandardTextList />
      </section>
    </MainLayout>
  );
};
