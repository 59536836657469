import { StandardTextEnriched, StandardTextPlatforms } from '@types';

const isTextDefault = (textItem: StandardTextEnriched) => textItem?.text?.default;

const reducePlatforms = (acc: Set<StandardTextPlatforms>, item: StandardTextEnriched) => {
  item.text.platforms.forEach(p => acc.add(p));
  return acc;
};

export const getDefaultPlatforms = (standardTextItems: StandardTextEnriched[]) => {
  return standardTextItems
    .filter(isTextDefault)
    .reduce(reducePlatforms, new Set<StandardTextPlatforms>());
};
