import React, { useEffect, useState } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTextarea,
} from '@didomi/ui-atoms-react';
import { TextLanguageData } from '@types';

interface StandardTextLanguageFormProps {
  languageData?: TextLanguageData;
  disabledLanguages: string[];
  onCancel?: () => void;
  onSave?: (languageData: TextLanguageData) => void;
}

export const StandardTextLanguageForm = ({
  languageData,
  disabledLanguages,
  onCancel,
  onSave,
}: StandardTextLanguageFormProps) => {
  const [langField, setLangField] = useState({ value: '', touched: false });
  const [contentField, setContentField] = useState({ value: '', touched: false });
  const langFieldError = langField.touched && !langField.value ? 'Required' : '';
  const contentFieldError = contentField.touched && !contentField.value ? 'Required' : '';

  useEffect(() => {
    setLangField({ value: languageData?.language || '', touched: false });
    setContentField({ value: languageData?.content || '', touched: false });
  }, [languageData]);

  const handleSave = () => {
    setLangField({ ...langField, touched: true });
    setContentField({ ...contentField, touched: true });

    if (langField.value && contentField.value) {
      onSave({ language: langField.value.trim(), content: contentField.value.trim() });
    }
  };

  return (
    <DidomiCardContainer className="w-full">
      <DidomiSelect
        name="language"
        placeholder="Choose a language in the list"
        label="Language"
        required
        error={langFieldError}
        value={langField.value}
        onBlur={() => setLangField({ ...langField, touched: true })}
        onValueChange={e => setLangField({ value: e.detail as string, touched: true })}
      >
        <DidomiSelectOptions>
          {supportedLanguages.map(l => (
            <DidomiSelectOption
              key={l.code}
              value={l.code}
              label={l.longName}
              disabled={disabledLanguages.includes(l.code)}
            />
          ))}
        </DidomiSelectOptions>
      </DidomiSelect>

      <DidomiTextarea
        name="content"
        label="Text"
        placeholder="Type your standard text..."
        rows={3}
        required
        error={contentFieldError}
        value={contentField.value}
        onBlur={() => setContentField({ ...contentField, touched: true })}
        onValueChange={e => setContentField({ value: e.detail, touched: true })}
      />

      <div className="flex gap-xxs justify-end">
        <DidomiButton type="button" variant="secondary" size="small" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton
          data-testid="save-text-language"
          type="button"
          variant="secondary"
          size="small"
          onClick={() => handleSave()}
        >
          Save
        </DidomiButton>
      </div>
    </DidomiCardContainer>
  );
};
