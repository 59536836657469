import { useCallback, useEffect, useState } from 'react';
import { matchId } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { PaginatedResponse, StandardText, StandardTextContent, StandardTextEnriched } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

const API_URL = CONFIG.environmentConfig.apiBaseUrl;

type StandardTextState = {
  isLoading: boolean;
  error: unknown;
  data: StandardTextEnriched[];
};

export const useStandardTexts = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const [requestState, setRequestState] = useState<StandardTextState>({
    isLoading: true,
    data: [],
    error: null,
  });

  const getData = useCallback(async () => {
    try {
      const standardTextResponse = await axiosWithInterceptors.get<PaginatedResponse<StandardText>>(
        `${API_URL}widgets/notices/texts?$limit=5000`,
      );
      const standardTextContentResponse = await axiosWithInterceptors.get<
        PaginatedResponse<StandardTextContent>
      >(`${API_URL}/widgets/notices/texts-contents?$limit=5000&$translations=true`);

      const standardTextItems = standardTextResponse.data.data;
      const standardTextContentItems = standardTextContentResponse.data.data;

      const contentEnriched = standardTextContentItems
        .filter(mappedText => mappedText.organization_id === organizationId)
        .map(textContent => ({
          ...textContent,
          text: standardTextItems.find(matchId(textContent.text_id)),
        }));

      setRequestState({ isLoading: false, error: null, data: contentEnriched });
    } catch (error) {
      setRequestState({ isLoading: false, error, data: [] });
    }
  }, [axiosWithInterceptors, organizationId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return requestState;
};
