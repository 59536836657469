import { getTranslatedValue } from '@didomi/helpers';
import { StandardTextEnriched, StatusFilterType } from '@types';

export const getFilteredStandardTexts = (
  statusFilter: StatusFilterType,
  search: string,
  standardTextItems: StandardTextEnriched[],
) => {
  const searchQuery = search.trim().toLowerCase();
  const matchStatus = (item: StandardTextEnriched) => !statusFilter || item.status === statusFilter;
  const matchSearch = (item: StandardTextEnriched) =>
    !searchQuery ||
    item.text.name.toLowerCase().includes(searchQuery) ||
    getTranslatedValue(item.content).toLowerCase().includes(searchQuery);

  return standardTextItems.filter(item => matchStatus(item) && matchSearch(item));
};
