import React from 'react';
import Highlighter from 'react-highlight-words';

type TextWithHighlighterProps = {
  text: string;
  searchText: string;
};

export const TextWithHighlighter = ({ text, searchText }: TextWithHighlighterProps) => {
  const safeSearchText = searchText.replace(/([.?*+^$[\]\\(){}|-])/g, '');

  return (
    <Highlighter
      highlightClassName="text-primary-pink-4 bg-transparent"
      searchWords={[safeSearchText]}
      textToHighlight={text}
    />
  );
};
