import { useEffect, useMemo, useState } from 'react';
import { NewStandardText, NewStandardTextContent, StandardTextEnriched } from '@types';
import { getDefaultPlatforms } from '@utils';
import { useAddStandardTexts } from './useAddStandardText';
import { useStandardTexts } from './useStandardTexts';

export const useStandardTextsState = () => {
  const { data: loadedItems, isLoading } = useStandardTexts();
  const { add, isSaving } = useAddStandardTexts();
  const [standardTextItems, setStandardTextItems] = useState<StandardTextEnriched[]>([]);

  const defaultPlatforms = useMemo(
    () => getDefaultPlatforms(standardTextItems),
    [standardTextItems],
  );

  useEffect(() => {
    setStandardTextItems(loadedItems);
  }, [loadedItems]);

  const addNewText = async (formData: {
    standardText: NewStandardText;
    standardTextContent: NewStandardTextContent;
  }) => {
    const { data, error } = await add(formData);

    if (data) {
      setStandardTextItems(standardTextItems.concat(data));
    }

    return { data, error };
  };

  return {
    items: standardTextItems,
    defaultPlatforms,
    addNewText,
    isLoading,
    isSaving,
  };
};
