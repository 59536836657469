export const ICON_PARAMS_PER_PLATFORM = {
  web: {
    name: 'consent-type-website',
    label: 'Website',
  },
  app: {
    name: 'consent-type-mobile',
    label: 'Mobile',
  },
  amp: {
    name: 'consent-type-amp',
    label: 'AMP',
  },
  ctv: {
    name: 'consent-type-ctv',
    label: 'CTV',
  },
} as const;

export const PLATFORM_LIST_OPTIONS = [
  { ...ICON_PARAMS_PER_PLATFORM.web, value: 'web' },
  { ...ICON_PARAMS_PER_PLATFORM.amp, value: 'amp' },
  { ...ICON_PARAMS_PER_PLATFORM.app, value: 'app' },
  { ...ICON_PARAMS_PER_PLATFORM.ctv, value: 'ctv' },
];
