import React from 'react';
import { DidomiTooltip, DidomiIcon } from '@didomi/ui-atoms-react';
import { ICON_PARAMS_PER_PLATFORM } from '@utils';

export const StandardTextCardPlatformIcon = ({ platform }: { platform: string }) => {
  const iconParams = ICON_PARAMS_PER_PLATFORM[platform];

  return (
    <DidomiTooltip className="leading-[0]" content={iconParams.label}>
      <DidomiIcon name={iconParams.name} aria-label={iconParams.label} />
    </DidomiTooltip>
  );
};
