export const CHIP_PARAMS_PER_TEXT_STATUS = {
  submitted: {
    type: 'info',
    icon: 'reset-sm',
    label: 'Submitted',
  },
  approved: {
    type: 'success',
    icon: 'check-sm',
    label: 'Approved',
  },
  rejected: {
    type: 'error',
    icon: 'close-sm',
    label: 'Rejected',
  },
} as const;
