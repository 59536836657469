import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiChip } from '@didomi/ui-atoms-react';
import { StandardTextEnriched } from '@types';
import { CHIP_PARAMS_PER_TEXT_STATUS } from '@utils';
import { StandardTextCardPlatformIcon } from './StandardTextCardPlatformIcon';
import { TextWithHighlighter } from '../TextWithHighlighter/TextWithHighlighter';

type StandardTextCardProps = {
  standardText: StandardTextEnriched;
  searchQuery?: string;
};

export const StandardTextCard = ({ standardText, searchQuery = '' }: StandardTextCardProps) => {
  const chipParams = CHIP_PARAMS_PER_TEXT_STATUS[standardText.status];
  const isInReview = standardText.status === 'submitted';
  const inReviewModifierClassName = isInReview && 'opacity-70';

  const Platforms = () => {
    return (
      <ul className="flex gap-2">
        {standardText.text?.platforms.map(platform => (
          <li key={platform}>
            <StandardTextCardPlatformIcon platform={platform} />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <section
      className={`border-1 border-solid border-neutral-gray-3 rounded-xl overflow-hidden text-primary-blue-6 ${
        !isInReview && 'shadow-card'
      }`}
    >
      <div className="flex items-center bg-neutral-gray-1 px-6 py-4">
        <div
          className={`font-bold font-serif text-h3 mr-auto ${inReviewModifierClassName}`}
          data-testid="standard-text-name"
        >
          <TextWithHighlighter searchText={searchQuery} text={standardText?.text?.name} />
        </div>

        {isInReview && (
          <div className="text-body-extra-small font-semibold ml-4">
            An expert reviews your standard text...
          </div>
        )}

        <DidomiChip
          className="ml-4"
          data-testid="standard-text-status"
          label={chipParams.label}
          basicType={chipParams.type}
          leftIcon={chipParams.icon}
        />
      </div>

      <div className={`flex flex-col gap-4 sm:(gap-12 flex-row) p-6 ${inReviewModifierClassName}`}>
        <div className="flex-shrink-0 sm:(border-r-1 border-solid border-neutral-gray-3 py-2 w-[200px])">
          <div className="flex items-center gap-2 mb-4" data-testid="text-platforms">
            <div className="text-body-extra-small font-bold w-[72px]">PLATFORM</div>
            <Platforms />
          </div>

          <div className="flex items-center gap-2" data-testid="text-default-platforms">
            <div className="text-body-extra-small font-bold w-[72px]">DEFAULT FOR</div>
            {standardText.text?.default ? <Platforms /> : '--'}
          </div>
        </div>

        <div className="text-body-small" data-testid="standard-text-content">
          <TextWithHighlighter
            searchText={searchQuery}
            text={getTranslatedValue(standardText.content)}
          />
        </div>
      </div>
    </section>
  );
};
