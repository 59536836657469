import { useCallback, useState } from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { NewStandardText, NewStandardTextContent, StandardText, StandardTextContent } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

const API_URL = CONFIG.environmentConfig.apiBaseUrl;

export const useAddStandardTexts = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const [isSaving, setIsSaving] = useState(false);

  const add = useCallback(
    async (data: {
      standardText: NewStandardText;
      standardTextContent: NewStandardTextContent;
    }) => {
      setIsSaving(true);

      try {
        const savedTextResponse = await axiosWithInterceptors.post<StandardText>(
          `${API_URL}widgets/notices/texts`,
          {
            ...data.standardText,
            organization_id: organizationId,
          },
        );
        const savedTextContentResponse = await axiosWithInterceptors.post<StandardTextContent>(
          `${API_URL}widgets/notices/texts-contents`,
          {
            ...data.standardTextContent,
            text_id: savedTextResponse.data.id,
            organization_id: organizationId,
          },
        );

        return {
          data: {
            ...savedTextContentResponse.data,
            text: savedTextResponse.data,
          },
          error: null,
        };
      } catch (error) {
        return { data: null, error: error.message };
      } finally {
        setIsSaving(false);
      }
    },
    [axiosWithInterceptors, organizationId],
  );

  return {
    add,
    isSaving,
  };
};
